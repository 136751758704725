import Vue from 'vue'
import VueI18n from 'vue-i18n'

import { getLanguage } from '@/utils/cookies'

// element-ui built-in lang
import elementEnLocale from 'element-ui/lib/locale/lang/en'
import elementFrLocale from 'element-ui/lib/locale/lang/fr'

// User defined lang
import enLocale from './en'
import frLocale from './fr'

const numberFormats = {
  en: {
    decimal: {
      style: 'decimal'
    },
    currency: {
      style: 'currency',
      currency: 'EUR',
      currencyDisplay: 'symbol'
    },
    percent: {
      style: 'percent',
      maximumFractionDigits: 2,
      minimumFractionDigits: 0
    }
  },
  fr: {
    decimal: {
      style: 'decimal'
    },
    currency: {
      style: 'currency',
      currency: 'EUR',
      currencyDisplay: 'symbol'
    },
    percent: {
      style: 'percent',
      minimumFractionDigits: 2
    }
  }
}

Vue.use(VueI18n)

const messages = {
  en: {
    ...enLocale,
    ...elementEnLocale
  },
  fr: {
    ...frLocale,
    ...elementFrLocale
  }
}

export const getLocale = () => {
  const cookieLanguage = getLanguage()
  if (cookieLanguage) {
    document.documentElement.lang = cookieLanguage
    return cookieLanguage
  }

  const language = navigator.language.toLowerCase()
  const locales = Object.keys(messages)
  for (const locale of locales) {
    if (language.indexOf(locale) > -1) {
      document.documentElement.lang = locale
      return locale
    }
  }

  // Default language is french
  return 'fr'
}

const i18n = new VueI18n({
  locale: getLocale(),
  numberFormats: numberFormats,
  messages
})

export default i18n
