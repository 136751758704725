export default {
  route: {
    dashboard: 'Tableau de bord',
    documentation: 'Documentation',
    guide: 'Guide',
    market: 'Market',
    users: 'Utilisateurs',
    buyers: 'Buyers',
    wholesalers: 'Wholesalers',
    sellers: 'Sellers',
    caretakers: 'Caretakers',
    orders: 'Commandes',
    customerOrders: 'Commandes clients',
    orderEdit: 'Édition commande',
    giftCards: 'Cartes cadeaux',
    giftCardEdit: 'Édition carte cadeau',
    giftCardCreate: 'Création carte cadeau',
    rateCards: 'Cartes tarifaires',
    rates: 'Taux',
    cards: 'Cartes',
    purchaseInfos: 'Informations d\'achat',
    purchaseInfosEdit: 'Édition informations d\'achat',
    purchaseInfosCreate: 'Création informations d\'achat',
    giftCardSellers: 'GiftCard Sellers',
    customers: 'Clients',
    advertisers: 'Advertisers',
    wallet: 'Wallet',
    categories: 'Catégories',
    skus: 'Skus',
    skuEdit: 'Sku édition',
    skuCreate: 'Sku création',
    deliveries: 'Livraisons',
    paymentModes: 'Modes de paiement',
    invoices: 'Factures',
    invoiceEdit: 'Édition facture',
    bankTransfers: 'Virements bancaires',
    balances: 'Balances',
    permission: 'Permission',
    pagePermission: 'Page Permission',
    rolePermission: 'Role Permission',
    directivePermission: 'Directive Permission',
    icons: 'Icons',
    components: 'Components',
    tinymce: 'Tinymce',
    markdown: 'Markdown',
    jsonEditor: 'JSON Editor',
    splitPane: 'SplitPane',
    avatarUpload: 'Avatar Upload',
    dropzone: 'Dropzone',
    sticky: 'Sticky',
    countTo: 'Count To',
    componentMixin: 'Mixin',
    backToTop: 'Haut de page',
    draggableDialog: 'Draggable Dialog',
    draggableKanban: 'Draggable Kanban',
    draggableList: 'Draggable List',
    draggableSelect: 'Draggable Select',
    example: 'Example',
    nested: 'Nested Routes',
    table: 'Table',
    dynamicTable: 'Tableau dynamique',
    draggableTable: 'Tableau déplaçable',
    inlineEditTable: 'Inline Edit Table',
    complexTable: 'Complex Table',
    tab: 'Tab',
    form: 'Form',
    errorPages: 'Pages d\'erreur',
    page401: '401',
    page404: '404',
    errorLog: 'Error Log',
    excel: 'Excel',
    exportExcel: 'Exporter Excel',
    selectExcel: 'Exporter la sélection',
    uploadExcel: 'Upload Excel',
    zip: 'Zip',
    pdf: 'PDF',
    exportZip: 'Export Zip',
    theme: 'Theme',
    clipboard: 'Presse-papiers',
    i18n: 'I18n',
    externalLink: 'Lien externe',
    profile: 'Profil'
  },
  navbar: {
    logOut: 'Déconnexion',
    dashboard: 'Tableau de bord',
    theme: 'Theme',
    profile: 'Profil'
  },
  login: {
    title: 'Login Form',
    logIn: 'Login',
    username: 'Username',
    password: 'Password',
    any: 'any',
    thirdparty: 'Ou se connecter avec',
    thirdpartyTips: 'Ne peut pas être simulé en local, donc veuillez combiner votre propre simulation d\'entreprise !'
  },
  permission: {
    createRole: 'Nouveau rôle',
    editPermission: 'Édition',
    roles: 'Vôtre rôle',
    switchRoles: 'Changer de rôle',
    tips: 'Dans certains cas, l\'utilisation de v-permission n\'aura aucun effet. Par exemple : Element-UI el-tab ou el-table-column et d\'autres scènes qui rendent dynamiquement le dom. Vous ne pouvez le faire qu\'avec v-if',
    delete: 'Supprimer',
    confirm: 'Confirmer',
    cancel: 'Annuler'
  },
  order: {
    title: 'Commandes acheteur',
    bulk: 'Commandes groupées',
    invoice: 'Facture(s) fournisseur'
  },
  invoice: {
    title: 'Facture(s) acheteur',
    bulk: 'Facture(s) fournisseur'
  },
  table: {
    title: 'Titre',
    importance: 'Importance',
    type: 'Type',
    remark: 'Remarque',
    search: 'Recherche',
    add: 'Ajouter',
    export: 'Exporter',
    reviewer: 'Reviewer',
    id: 'ID',
    date: 'Date',
    author: 'Auteur',
    readings: 'Lectures',
    status: 'Statut',
    actions: 'Actions',
    edit: 'Édition',
    publish: 'Publier',
    draft: 'Brouillon',
    delete: 'Supprimer',
    cancel: 'Annuler',
    confirm: 'Confirmer',
    email: 'E-mail',
    name: 'Nom',
    firstname: 'Prénom',
    lastname: 'Nom de famille',
    password: 'Mot de passe',
    passwordConfirmation: 'Confirmer le mot de passe',
    confirmedAt: 'Confirmé le'
  },
  wallet: {
    usageStatus: {
      unused: 'Inutilisé',
      partially_used: 'Partiellement utilisé',
      used: 'Utilisé',
      archived: 'Archivé'
    }
  },
  errorLog: {
    tips: 'Veuillez cliquer sur l\'icône "Bug" dans le coin supérieur droit',
    description: 'Maintenant le système de gestion sont essentiellement la forme du spa, il améliore l\'expérience de l\'utilisateur, mais il augmente également la possibilité de problèmes de page, une petite négligence peut conduire à la page entière impasse. Heureusement Vue fournit un moyen d\'attraper les exceptions de manipulation, où vous pouvez gérer les erreurs ou signaler les exceptions.',
    documentation: 'Introduction au document'
  },
  excel: {
    export: 'Exporter',
    selectedExport: 'Exporter les éléments sélectionnés',
    placeholder: 'Veuillez saisir le nom du fichier (excel-list par défaut)'
  },
  zip: {
    export: 'Exporter',
    placeholder: 'Veuillez saisir le nom du fichier'
  },
  theme: {
    change: 'Changer de thème'
  },
  tagsView: {
    refresh: 'Rafraîchir',
    close: 'Fermer',
    closeOthers: 'Fermer les autres',
    closeAll: 'Tout fermer'
  },
  settings: {
    title: 'Paramètres du style de la page',
    theme: 'Couleur du thème',
    showTagsView: 'Afficher les Tags-View',
    showSidebarLogo: 'Logo de la barre latérale',
    fixedHeader: 'En-tête fixe',
    sidebarTextTheme: 'Thème de texte de la barre latérale'
  }
}
