/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'hourglass': {
    width: 46,
    height: 46,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12.75 14.25h-1.5v1.5h1.5v-1.5zM12.75 17.25h-1.5v1.5h1.5v-1.5z"/><path pid="1" d="M17.25 8.752V3h2.25V1.5h-15V3h2.25v5.752c0 .325.105.64.3.9L8.813 12 7.05 14.348a1.5 1.5 0 00-.3.9V21H4.5v1.5h15V21h-2.25v-5.752a1.5 1.5 0 00-.3-.9L15.188 12l1.762-2.348a1.5 1.5 0 00.3-.9zM15.75 3v5.25h-7.5V3h7.5zm0 12.248V21h-7.5v-5.752L10.688 12 9 9.75h6L13.312 12l2.438 3.248z"/>'
  }
})
