










































































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import Pagination from '@/components/Pagination/index.vue'
import { Card, Delivery } from '@/models/index'

@Component({
  name: 'CardsManagerDrawer',
  components: {
    Pagination
  }
})
export default class extends Vue {
  @Prop({ required: true }) private visible!: boolean
  @Prop({ required: true }) private isBulk!: boolean
  @Prop({ required: true }) private delivery!: Delivery

  @Watch('visible')
  private onVisibleChange(value: boolean) {
    if (value) this.getCards()
  }

  private direction = 'btt'

  private cards: Card[] = []
  private cardsLoading = false
  private totalCards = 0
  private transferLoading = false

  private listQuery = {
    page: 1,
    limit: 10,
    number: undefined
  }

  created() {
    this.getCards()
  }

  private async getCards() {
    this.cardsLoading = true
    const includes: any = this.isBulk ? { delivery: 'order' } : { bulkDelivery: 'order' }
    const where = this.isBulk ? { bulkDeliveryId: this.delivery.id } : { deliveryId: this.delivery.id }
    const { data, meta } = await Card
      .includes(includes)
      .where(where)
      .page(this.listQuery.page)
      .per(this.listQuery.limit)
      .stats({ total: 'count' })
      .all()

    this.cards = data
    this.totalCards = meta.stats.total.count
    this.cardsLoading = false
  }

  // private async transferOrder(bulk: Order) {
  //   this.transferLoading = true

  //   try {
  //     await this.order.transfer(bulk)

  //     this.$emit('done', this.order)
  //   } catch (err) { }

  //   this.transferLoading = false
  // }
}
