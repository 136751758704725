<template>
  <el-upload
      class="w-100"
      :class="{'w-full-upload': fullWidth}"
      :list-type="listType"
      :drag="drag"
      :action="uploadUrl"
      :file-list="blobList"
      :multiple="multiple"
      :http-request="upload"
  >
    <template v-if="!button">
      <i class="el-icon-upload"></i>
      <div class="el-upload__text">Déposer les fichiers ici ou <em>cliquez pour envoyer</em></div>
    </template>
    <template v-else>
      <el-button size="small" type="info" class="w-100">
        <i class="el-icon-upload"></i>
        Cliquez pour envoyer
      </el-button>
    </template>
  </el-upload>
</template>

<script>
import { UserModule } from '@/store/modules/user'
import { DirectUpload } from 'activestorage'

export default {
  name: 'DirectUpload',
  props: {
    uploadUrl: {
      type: String,
      required: false,
      default: `${process.env.VUE_APP_API_URL}/rails/active_storage/direct_uploads`
    },
    multiple: {
      type: Boolean,
      default: true
    },
    fileList: {
      type: Array,
      required: false,
      default: () => []
    },
    tip: {
      type: String,
      required: false,
      default: 'Fichiers jpg/png avec une taille inférieure à 500kb'
    },
    button: {
      type: Boolean,
      required: false,
      default: false
    },
    listType: {
      type: String,
      required: false,
      default: 'picture'
    },
    drag: {
      type: Boolean,
      required: false,
      default: true
    },
    fullWidth: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      progress: 0,
      uploads: []
    }
  },
  methods: {
    onProgress(event) {
      this.progress = (event.loaded / event.total) * 100
    },
    upload(file) {
      const upload = new DirectUpload(file.file, file.action, {
        directUploadWillCreateBlobWithXHR: (xhr) => {
          xhr.setRequestHeader('Authorization', `Bearer ${UserModule.user.access_token}`)
          this.lastXhr = xhr
        }
      })
      this.uploads.push({ file, upload })
      this.progress = 0
      upload.create((error, blob) => {
        if (error) {
          // TODO
        } else {
          this.$emit('upload', { file, blob })
        }
      })
    }
  },
  computed: {
    blobList() {
      return this.fileList.map(e => { return { name: e?.filename || 'File', url: e?.url || '' } })
    },
    uploadInProgress() { return this.uploads.length > 0 }
  }
}
</script>

<style lang="scss">
.w-100 {
  width: 100% !important;
}

.w-full-upload {
  .el-upload, .el-upload-dragger {
    width: 100% !important;
  }
}

.drop-zone {
  border: 3px solid #eee;
  min-height: 50px;
}
.drop-zone.dragging {
  border: 3px dashed #444;
  position: relative;
}
.drop-zone.dragging:before {
  content: "Lassen Sie die Datei hier fallen um den Upload zu beginnen";
  display: block;
  position: absolute;
  top: 0;
  right: 5px;
  font-style: italic;
  color: #555;
}
input[type=file][data-direct-upload-url][disabled] {
  display: none;
}
</style>
