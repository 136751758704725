




























































import { Component, Vue, Prop } from 'vue-property-decorator'

import { GiftCard } from '@/models/index'

@Component({
  name: 'GiftCardSelect'
})
export default class extends Vue {
  @Prop({ required: true }) private selected!: string
  @Prop({ required: true }) private giftCards!: GiftCard[]
  @Prop({ required: false, default: false }) private loading!: boolean
  @Prop({ required: false, default: false }) private disabled!: boolean
  @Prop({ required: false, default: 'Select a Gift Card' }) private placeholder!: string
  @Prop({ required: false, default: false }) private clearable!: boolean
  @Prop({ required: false, default: false }) private displayStock!: boolean
  @Prop({ required: false, default: false }) private displayDiscount!: boolean
  @Prop({ required: false, default: false }) private multiple!: boolean
  @Prop({ required: false, default: true }) private fullWidth!: boolean
  @Prop({ required: false, default: true }) private group!: boolean
}
