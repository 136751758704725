








































import { Component, Vue, Prop } from 'vue-property-decorator'
import { Order } from '@/models/index'

@Component({
  name: 'CardDistributionEditDrawer',
  components: {
  }
})
export default class extends Vue {
  @Prop({ required: true }) private visible!: boolean
  @Prop({ required: true }) private order!: Order

  private direction = 'rtl'

  get freeFaceValue() {
    const sku = this.order.giftCard.skus.find(e => e.faceValueFree)
    return sku !== undefined && !sku.discardedAt
  }

  get faceValueAvailable() {
    return this.order.giftCard.skus.map(e => {
      return { label: `${e.faceValue} €`, value: e.id }
    })
  }
}
