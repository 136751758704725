/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'supply': {
    width: 448,
    height: 448,
    viewBox: '0 0 448 448',
    data: '<path pid="0" d="M176 272h-16v40h-48v-40H96v112h80zm-32 96h-32v-16h32zm0 0M80 16H64v40H16V16H8c-4.8 0-8 3.2-8 8v104h80zm-32 96H16V96h32zm0 0"/><path pid="1" d="M32 16h16v24H32zm0 0M128 272h16v24h-16zm0 0M128 144h16v24h-16zm0 0M128 16h16v24h-16zm0 0M32 144h16v24H32zm0 0"/><path pid="2" d="M176 24c0-4.8-3.2-8-8-8h-8v40h-48V16H96v112h80zm-32 88h-32V96h32zm0 0M176 144h-16v40h-48v-40H96v112h80zm-32 96h-32v-16h32zm0 0M80 272H64v40H16v-40H0v112h80zm-32 96H16v-16h32zm0 0M336 432c0 8.836-7.164 16-16 16s-16-7.164-16-16 7.164-16 16-16 16 7.164 16 16zm0 0M416 432c0 8.836-7.164 16-16 16s-16-7.164-16-16 7.164-16 16-16 16 7.164 16 16zm0 0"/><path pid="3" d="M288 400h-48V0h-16v400H0v24h288.8c4-16.8 21.598-27.2 38.4-23.2 11.198 3.2 20 12 23.198 23.2h18.403c4-16.8 21.597-27.2 38.398-23.2 11.2 3.2 20 12 23.2 23.2H440c4.8 0 8-3.2 8-8v-72H288zm0 0"/><path pid="4" d="M440 160h-65.602c-2.398 0-4 .8-5.597 2.398l-78.403 78.403C288.801 242.398 288 244 288 246.398v62.403L328.8 268l-14.402-14.398 11.204-11.204 40 40-11.204 11.204L340 279.199 291.2 328H376v-16c0-13.602 10.398-24 24-24h8c4.8 0 8-3.2 8-8v-56c0-13.602 10.398-24 24-24h8v-32c0-4.8-3.2-8-8-8zm0 0"/><path pid="5" d="M432 224v56c0 13.602-10.398 24-24 24h-8c-4.8 0-8 3.2-8 8v16h56V216h-8c-4.8 0-8 3.2-8 8zm0 0M80 144H64v40H16v-40H0v112h80zm-32 96H16v-16h32zm0 0M32 272h16v24H32zm0 0"/>'
  }
})
