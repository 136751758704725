/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'order-btob': {
    width: 512,
    height: 512,
    viewBox: '0 0 512.021 512.021',
    data: '<path pid="0" d="M110 159.51c0-8.284-6.716-15-15-15H15c-8.284 0-15 6.716-15 15s6.716 15 15 15h80c8.284 0 15-6.716 15-15zM118 215.51c0-8.284-6.716-15-15-15H39c-8.284 0-15 6.716-15 15s6.716 15 15 15h64c8.284 0 15-6.716 15-15zM112 256.51H63c-8.284 0-15 6.716-15 15s6.716 15 15 15h49c8.284 0 15-6.716 15-15s-6.716-15-15-15z"/><path pid="1" d="M497 56.51H383v-25c0-8.284-6.716-15-15-15H200c-8.284 0-15 6.716-15 15v114h-21.298l-11.931-67.606A15 15 0 00137 65.51H57c-8.284 0-15 6.716-15 15s6.716 15 15 15h67.415l42.884 243.007C146.021 343 130 361.919 130 384.51c0 25.916 21.084 47 47 47h12.195c-11.969 30.737 10.85 64 43.805 64 32.992 0 55.764-33.291 43.805-64h56.39c-11.969 30.737 10.85 64 43.805 64 32.992 0 55.764-33.291 43.805-64H449c8.284 0 15-6.716 15-15s-6.716-15-15-15H177c-9.374 0-17-7.626-17-17s7.626-17 17-17h280a15.001 15.001 0 0014.685-11.94l40-192c.456-2.188.315 5.377.315-92.06 0-8.284-6.716-15-15-15zm-247 392c0 9.374-7.626 17-17 17s-17-7.626-17-17 7.626-17 17-17 17 7.626 17 17zm144 0c0 9.374-7.626 17-17 17s-17-7.626-17-17 7.626-17 17-17 17 7.626 17 17zm23.5-362v17.759c0 8.284 6.716 15 15 15s15-6.716 15-15V86.51H482v59h-99v-59zm-202.5-40h54v25c0 8.284 6.716 15 15 15s15-6.716 15-15v-25h54v99H215zm229.803 291H197.584l-28.588-162h309.557z"/>'
  }
})
