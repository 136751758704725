/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'grouped-orders': {
    width: 512,
    height: 512,
    viewBox: '0 0 509.918 509.918',
    data: '<path pid="0" d="M470.381 264.324V32.122c0-8.284-6.716-15-15-15H53.499c-8.284 0-15 6.716-15 15v250.713a45.4 45.4 0 00-20.568 8.509c-19.728 13.989-24.394 45.295-7.869 64.602l67.563 81.907c41.822 48.947 86.249 54.944 152.295 54.944 56.677 0 82.016.25 131.411-11.005l48.098-11.504c7.799 10.891 20.18 17.946 34.099 17.946h23.694c23.542 0 42.695-20.177 42.695-44.978V309.167c.001-23.681-17.465-43.133-39.536-44.843zm-67.422 30.856l-19.986-10.151c-32.861-16.587-70.854-17.201-104.234-1.682-9.107 3.582-28.97 15.6-39.273 15.198h-69.352c-23.004 0-41.72 18.715-41.72 41.72v10.939c-.129-.136-.264-.265-.391-.403l-49.525-53.757a45.683 45.683 0 00-9.978-8.159V140.066h122.753v54.986c0 8.284 6.716 15 15 15h94.651c8.284 0 15-6.716 15-15v-54.986h124.478v124.257c-17.526 1.354-32.14 13.892-37.423 30.857zM221.252 140.066h64.651v39.986h-64.651zm219.129-30H315.904V47.122h124.478v62.944zM285.904 47.122v62.945h-64.651V47.122zm-94.652 0v62.945H68.499V47.122zM354.369 452.61c-45.63 10.451-73.111 10.028-123.911 10.028-62 0-91.957-1.002-129.689-43.876l-67.563-81.907c-11.752-15.303 9.534-33.368 23.208-19.486l49.525 53.758c13.089 13.811 29.56 21.412 49.861 21.859h137.308c8.284 0 15-6.716 15-15s-6.716-15-15-15H158.393v-22.723c0-6.462 5.257-11.72 11.72-11.72h69.352c15.624 1.119 38.175-11.927 51.921-17.994 25-11.624 53.456-11.164 78.035 1.242l31.414 15.956v113.748zm125.549-9.355c0 8.259-5.695 14.978-12.695 14.978h-23.694c-7 0-12.694-6.719-12.694-14.978V309.167c0-8.259 5.695-14.979 12.694-14.979h23.694c7 0 12.695 6.719 12.695 14.979z"/>'
  }
})
