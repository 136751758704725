/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'grouped-invoices': {
    width: 576,
    height: 576,
    viewBox: '-34 0 432 432.008',
    data: '<path pid="0" d="M82.004 158.004c0 3.312 2.687 6 6 6h14.789a64.587 64.587 0 0033.965 36.535 64.577 64.577 0 0049.86 1.45 6.002 6.002 0 10-4.313-11.204c-25.774 9.934-54.836-1.762-66.543-26.781h52.242c3.312 0 6-2.688 6-6s-2.688-6-6-6h-56.156a52.865 52.865 0 01-.442-18h63.598c3.312 0 6-2.688 6-6s-2.688-6-6-6h-60.352c10.774-26.602 40.88-39.652 67.657-29.328a6.005 6.005 0 007.757-3.446 6.005 6.005 0 00-3.445-7.757 64.522 64.522 0 00-84.695 40.531H88.004a6.001 6.001 0 000 12H99.32a64.923 64.923 0 00.356 18H88.004c-3.313 0-6 2.687-6 6zm0 0"/><path pid="1" d="M6.035 364.004h27.969v27.969a6.3 6.3 0 006.312 6.03h28.688v28.25a5.454 5.454 0 005.59 5.75h283.379a5.822 5.822 0 006.03-5.75V74.317a6.3 6.3 0 00-6.03-6.312h-27.97V40.035a6.3 6.3 0 00-6.312-6.031h-28.687V5.754a5.454 5.454 0 00-5.59-5.75H6.035a5.818 5.818 0 00-6.031 5.75V357.69a6.3 6.3 0 006.031 6.313zm323.969 27.969V80.003h22v340h-271v-22H323.69a6.3 6.3 0 006.313-6.03zm-35-34.282V46.004h23v340h-272v-22h243.41c3.313 0 5.59-3 5.59-6.313zm-283-345.687h271v340h-271zm0 0"/><path pid="2" d="M255.133 317.004H40.316c-3.316 0-6 2.687-6 6s2.684 6 6 6h214.817c3.316 0 6-2.688 6-6s-2.684-6-6-6zm0 0M255.133 283.004H40.316c-3.316 0-6 2.687-6 6s2.684 6 6 6h214.817c3.316 0 6-2.688 6-6s-2.684-6-6-6zm0 0M40.316 261.004h77.7c3.312 0 6-2.688 6-6s-2.688-6-6-6h-77.7c-3.316 0-6 2.687-6 6s2.684 6 6 6zm0 0"/>'
  }
})
